var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid position-relative" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex pb-22 align-items-center justify-content-between"
        },
        [
          _c("span", { staticClass: "fw700 pb-md-0" }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.left",
                  modifiers: { hover: true, left: true }
                }
              ],
              staticClass: "d-flex",
              attrs: {
                title: _vm.disableAdd
                  ? "You have reached maximum user limit"
                  : ""
              }
            },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.left",
                      modifiers: { hover: true, left: true }
                    }
                  ],
                  attrs: { variant: "primary", disabled: _vm.disableAdd },
                  on: { click: _vm.addNewUser }
                },
                [
                  _c("img", {
                    staticClass: "adduser mr-5",
                    attrs: {
                      src: require("@/assets/images/adduser.png"),
                      alt: "ADD_USER_IMG"
                    }
                  }),
                  _vm._v(" Add New User")
                ]
              )
            ],
            1
          )
        ]
      ),
      this.windowWidth > 767
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "light-header manifests",
                attrs: {
                  items: _vm.items,
                  fields: _vm.fields,
                  "per-page": _vm.perPage,
                  busy: _vm.isBusy,
                  "sticky-header": "",
                  "no-border-collapse": "",
                  id: "manifests-table"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(id)",
                      fn: function(data) {
                        return [_vm._v(" " + _vm._s(data.item.id) + " ")]
                      }
                    },
                    {
                      key: "cell(date)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "w74 d-block" }, [
                            _vm._v(
                              " " + _vm._s(_vm._f("date")(data.item.date)) + " "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(action)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary btn-xs" },
                              on: {
                                click: function($event) {
                                  return _vm.editUser(data.item.id)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "editsvg mr-3",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 512 512"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" Edit ")
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: {
                                "background-color": "#fa5a5a !important"
                              },
                              attrs: {
                                variant: "primary btn-xs ml-20 ml-md-15"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.deleteUser(data.item.id)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "editsvg mr-3",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    viewBox: "0 0 448 512"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" Delete ")
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "span",
                              { staticClass: "text-truncated" },
                              [
                                _c("b-form-checkbox", {
                                  staticClass: "d-inline",
                                  attrs: { name: "default-pickup", switch: "" },
                                  on: {
                                    change: function($event) {
                                      return _vm.userStatus(
                                        data.item.id,
                                        data.item.status
                                      )
                                    }
                                  },
                                  model: {
                                    value: data.item.status,
                                    callback: function($$v) {
                                      _vm.$set(data.item, "status", $$v)
                                    },
                                    expression: "data.item.status"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "fs12 mt-2 lh16 text-gray-600 text-truncate"
                              },
                              [
                                data.item.status
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#65d678" } },
                                      [_vm._v("Enabled")]
                                    )
                                  : _c("span", [_vm._v("Disabled")])
                              ]
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4050658386
                )
              })
            ],
            1
          )
        : _c(
            "ul",
            {
              staticClass: "table-cards table-cards--shipments d-flex d-md-none"
            },
            _vm._l(_vm.items, function(item) {
              return _c("li", { key: item.id }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between w-100" },
                  [
                    _c("span", { staticClass: "d-block fw500 pb-8 cp" }, [
                      _vm._v(" User : " + _vm._s(item.name) + " ")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-end justify-content-between"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary btn-xs" },
                            on: {
                              click: function($event) {
                                return _vm.editUser(item.id)
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "editsvg ",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 512 512"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" Edit ")
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticStyle: {
                              "background-color": "#fa5a5a !important"
                            },
                            attrs: { variant: "primary btn-xs ml-10 ml-md-10" },
                            on: {
                              click: function($event) {
                                return _vm.deleteUser(item.id)
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "editsvg mr-3",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  viewBox: "0 0 448 512"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" Delete ")
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-end justify-content-between w-100 mb-12"
                  },
                  [
                    _c("div", { staticClass: "info" }, [
                      _c("span", { staticClass: "carrier-mode" }, [
                        _vm._v(" " + _vm._s(item.email) + " ")
                      ]),
                      _c("span", { staticClass: "carrier-name" }, [
                        _vm._v(" " + _vm._s(item.contact) + " ")
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex w-100 align-items-end justify-content-between"
                  },
                  [
                    _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                      _vm._v(" " + _vm._s(item.role) + " ")
                    ]),
                    _c("span", { class: item.status }, [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c(
                          "span",
                          { staticClass: "text-truncated" },
                          [
                            _c("b-form-checkbox", {
                              staticClass: "d-inline",
                              attrs: { name: "default-pickup", switch: "" },
                              on: {
                                change: function($event) {
                                  return _vm.userStatus(item.id, item.status)
                                }
                              },
                              model: {
                                value: item.status,
                                callback: function($$v) {
                                  _vm.$set(item, "status", $$v)
                                },
                                expression: "item.status"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "fs12 mt-2 lh16 text-gray-600 text-truncate"
                          },
                          [
                            item.status
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#65d678" } },
                                  [_vm._v("Enabled")]
                                )
                              : _c("span", [_vm._v("Disabled")])
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            }),
            0
          ),
      _vm.items.length == 0 && !_vm.isBusy ? _c("ResultNotFound") : _vm._e(),
      _c("AddNewUser", {
        ref: "addNewUser",
        attrs: { editUserflag: _vm.editUserflag, userEmail: _vm.userEmail },
        on: {
          "update:editUserflag": function($event) {
            _vm.editUserflag = $event
          },
          "update:edit-userflag": function($event) {
            _vm.editUserflag = $event
          },
          "update:userEmail": function($event) {
            _vm.userEmail = $event
          },
          "update:user-email": function($event) {
            _vm.userEmail = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }