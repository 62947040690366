var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "add-sku-modal",
        "modal-class": "add-sku-modal",
        size: "lg",
        "hide-footer": ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _vm.editUserflag
                ? _c(
                    "span",
                    { staticClass: "d-block mt-auto fs18 lh28 fw500" },
                    [_vm._v(" Edit User ")]
                  )
                : _c(
                    "span",
                    { staticClass: "d-block mt-auto fs18 lh28 fw500" },
                    [_vm._v(" Add New User ")]
                  ),
              _c(
                "b-button",
                {
                  attrs: { variant: "rounded" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_c("i", { staticClass: "icon-close-variant" })]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c("div", { staticClass: "mw596 mx-auto" }, [
        _c("div", { staticClass: "pt-md-25 pt-25 pb-md-10 pb-10" }, [
          _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSubmit($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "sku-field",
                              label: "User Name",
                              "label-for": "sku"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                required: "",
                                id: "sku",
                                placeholder: "User Name"
                              },
                              model: {
                                value: _vm.form.userName,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "userName", $$v)
                                },
                                expression: "form.userName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "item-name-field",
                              label: "User's Email",
                              "label-for": "item-name"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                required: "",
                                id: "item-name",
                                disabled: _vm.editUserflag,
                                placeholder: "User's Email",
                                maxlength: "100"
                              },
                              model: {
                                value: _vm.form.userEmail,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "userEmail", $$v)
                                },
                                expression: "form.userEmail"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "item-weight-field",
                              label: "Contact Number",
                              "label-for": "item-weight"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                required: "",
                                id: "item-weight",
                                type: "text",
                                maxlength: "10",
                                minlength: "10",
                                pattern: "[6-9]{1}[0-9]{9}",
                                placeholder: "Contact Number"
                              },
                              model: {
                                value: _vm.form.contactNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "contactNumber", $$v)
                                },
                                expression: "form.contactNumber"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6 col-12" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "mode-field",
                              label: "Role",
                              "label-for": "weight"
                            }
                          },
                          [
                            _c("multiselect", {
                              attrs: {
                                id: "mode",
                                options: _vm.options,
                                placeholder: "Select Role",
                                searchable: false,
                                "show-labels": false,
                                "allow-empty": false
                              },
                              model: {
                                value: _vm.form.userRole,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "userRole", $$v)
                                },
                                expression: "form.userRole"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  !_vm.editUserflag
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-12 mt-20" },
                          [
                            _c("b-form-checkbox", {
                              staticClass: "d-inline",
                              attrs: { name: "default-pickup", switch: "" },
                              on: { change: function($event) {} },
                              model: {
                                value: _vm.generatPassword,
                                callback: function($$v) {
                                  _vm.generatPassword = $$v
                                },
                                expression: "generatPassword"
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "fs16 text-gray-600 pl-10" },
                              [_vm._v("Add password manually")]
                            )
                          ],
                          1
                        ),
                        _vm.generatPassword
                          ? _c(
                              "div",
                              { staticClass: "col-md-6 col-12" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "item-weight-field",
                                      label: "Password",
                                      "label-for": "item-weight"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        required: "",
                                        id: "item-weight",
                                        type: "text",
                                        minlength: "8",
                                        placeholder: "User Password"
                                      },
                                      model: {
                                        value: _vm.form.password,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "password", $$v)
                                        },
                                        expression: "form.password"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-12",
                        class: { "pt-30": !_vm.generatPassword }
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              disabled: _vm.errorFlag,
                              variant: "primary",
                              size: "lg"
                            }
                          },
                          [
                            _vm.submitting ? _c("b-spinner") : _vm._e(),
                            _vm.editUserflag
                              ? _c("span", [_vm._v("Update")])
                              : _c("span", [_vm._v("Add")])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    !_vm.editUserflag
                      ? _c("div", { staticClass: "col-12 pt-md-20 pt-20" }, [
                          _c("span", { staticClass: "fs16 text-gray-600" }, [
                            _c("strong", [_vm._v("NOTE : ")]),
                            _vm._v("Maximum User limit is 20")
                          ])
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "addUser" }),
        _c("div", { staticClass: "pt-10" }, [
          _c("span", { staticClass: "fs18 mt-md-10 text-gray-600 " }, [
            _c("strong", [_vm._v("Role-Based Accessibility")])
          ])
        ]),
        _c(
          "div",
          { staticClass: "py-15" },
          [
            _c("b-table", {
              staticClass: "dark-header serviceability",
              attrs: {
                items: _vm.items,
                fields: _vm.fields,
                "no-border-collapse": "",
                responsive: "",
                id: "serviceability-table"
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(Admin)",
                  fn: function(data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "ml-14" },
                        [
                          data.item.Admin == true
                            ? [
                                _c("i", {
                                  staticClass: "icon-booked text-green fs12"
                                })
                              ]
                            : [
                                _c("i", {
                                  staticClass: "icon-failed text-red fs10"
                                })
                              ]
                        ],
                        2
                      )
                    ]
                  }
                },
                {
                  key: "cell(Operations)",
                  fn: function(data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "ml-6" },
                        [
                          data.item.Operations == true
                            ? [
                                _c("i", {
                                  staticClass: "icon-booked text-green fs12"
                                })
                              ]
                            : [
                                _c("i", {
                                  staticClass: "icon-failed text-red fs10"
                                })
                              ]
                        ],
                        2
                      )
                    ]
                  }
                },
                {
                  key: "cell(Finance)",
                  fn: function(data) {
                    return [
                      _c(
                        "div",
                        { staticClass: "ml-14" },
                        [
                          data.item.Finance == true
                            ? [
                                _c("i", {
                                  staticClass: "icon-booked text-green fs12"
                                })
                              ]
                            : [
                                _c("i", {
                                  staticClass: "icon-failed text-red fs10"
                                })
                              ]
                        ],
                        2
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }