<template>
  <div class="manifests">
    
    <userTable></userTable>
  </div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import userTable from "@/views/userManagement/userTable.vue";

export default {
  name: "UserManagement",
  title: "Shyplite - User Management",
  components: {
    AppTopbar,
    userTable,
  },
};
</script>
