<template>
  <b-modal
    id="add-sku-modal"
    modal-class="add-sku-modal"
    size="lg"
    hide-footer
    v-model="isOpen"
  >
    <template v-slot:modal-header="{ close }">
      <span v-if="editUserflag" class="d-block mt-auto fs18 lh28 fw500"> Edit User </span>
      <span v-else class="d-block mt-auto fs18 lh28 fw500"> Add New User </span>
      <b-button variant="rounded" @click="close()">
        <i class="icon-close-variant"></i>
      </b-button>
    </template>

    <div class="mw596 mx-auto">
      <div class="pt-md-25 pt-25 pb-md-10 pb-10">
        <div class="form">
          <b-form @submit.prevent="onSubmit">
            <div class="row">
              <div class="col-md-6 col-12">
                <b-form-group id="sku-field" label="User Name" label-for="sku">
                  <b-form-input
                    required
                    id="sku"
                    v-model="form.userName"
                    placeholder="User Name"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-name-field"
                  label="User's Email"
                  label-for="item-name"
                >
                  <b-form-input
                    required
                    id="item-name"
                    :disabled="editUserflag"
                    v-model="form.userEmail"
                    placeholder="User's Email"
                    maxlength="100"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12">
                <b-form-group
                  id="item-weight-field"
                  label="Contact Number"
                  label-for="item-weight"
                >
                  <b-form-input
                    required
                    id="item-weight"
                    type="text"
                    maxlength="10"
                    minlength="10"
                    pattern="[6-9]{1}[0-9]{9}"
                    v-model="form.contactNumber"
                    placeholder="Contact Number"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6 col-12">
                <b-form-group id="mode-field" label="Role" label-for="weight">
                  <multiselect
                    id="mode"
                    v-model="form.userRole"
                    :options="options"
                    placeholder="Select Role"
                    :searchable="false"
                    :show-labels="false"
                    :allow-empty="false"
                  >
                  </multiselect>
                </b-form-group>
              </div>
            </div>

            <div class="row" v-if="!editUserflag">
              <div class="col-md-6 col-12 mt-20">
                <b-form-checkbox
                class="d-inline"
                name="default-pickup"
                v-model="generatPassword"
                switch
                @change=""
              ></b-form-checkbox>
              <span class="fs16 text-gray-600 pl-10">Add password manually</span>
              </div>
              <div v-if="generatPassword" class="col-md-6 col-12">
                <b-form-group
                  id="item-weight-field"
                  label="Password"
                  label-for="item-weight"
                >
                  <b-form-input
                    required
                    id="item-weight"
                    type="text"
                    minlength="8"
                    v-model="form.password"
                    placeholder="User Password"
                  ></b-form-input>
                </b-form-group>
              </div>
              
            </div>

            <div class="row">
              <div class="col-12" :class="{'pt-30': !generatPassword }">
                <b-button type="submit" :disabled="errorFlag" variant="primary" size="lg">
                  <b-spinner v-if="submitting"></b-spinner>
                  <span v-if="editUserflag">Update</span><span v-else>Add</span>
                </b-button>
              </div>
              <div v-if="!editUserflag" class="col-12 pt-md-20 pt-20">
                <span class="fs16 text-gray-600"><strong>NOTE : </strong>Maximum User limit is 20</span>
              </div>
            </div>
          </b-form>
        </div>
      </div>

      <div class="addUser"></div>

      <div class="pt-10"><span class="fs18 mt-md-10 text-gray-600 "><strong>Role-Based Accessibility</strong></span></div>

      <div class="py-15">
        <b-table
          :items="items"
          :fields="fields"
          no-border-collapse
          responsive
          class="dark-header serviceability"
          id="serviceability-table"
        >
          <!-- A custom formatted data column cell for field 'Admin' -->
          <template v-slot:cell(Admin)="data">
            <div class="ml-14">
              <template v-if="data.item.Admin == true">
                <i class="icon-booked text-green fs12"></i>
              </template>
              <template v-else>
                <i class="icon-failed text-red fs10"></i>
              </template>
            </div>
          </template>

          <!-- A custom formatted data column cell for field 'Operations' -->
          <template v-slot:cell(Operations)="data">
            <div class="ml-6">
              <template v-if="data.item.Operations == true">
                <i class="icon-booked text-green fs12"></i>
              </template>
              <template v-else>
                <i class="icon-failed text-red fs10"></i>
              </template>
            </div>
          </template>

          <!-- A custom formatted data column cell for field 'Finance' -->
          <template v-slot:cell(Finance)="data">
            <div class="ml-14">
              <template v-if="data.item.Finance == true">
                <i class="icon-booked text-green fs12"></i>
              </template>
              <template v-else>
                <i class="icon-failed text-red fs10"></i>
              </template>
            </div>
          </template>
        </b-table>
      </div>
    </div>

  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import draggable from "vuedraggable";

export default {
  name: "AddNewUser",
  props:['userEmail','editUserflag'],
  data() {
    return {
      submitting:false,
      form: {
        
      },
      generatPassword:false,
      files: null,
      active: false,
      browseText: "Browse",
      isOpen: this.modalOpen,
      itemAdded: false,
      isbulkactive: false,
      uploading: false,
      fileUpload: false,
      options: ['ADMIN','OPERATIONS','FINANCE'],
      error:"",
      errorFlag:false,
      errormode:false,
      fields: [
        {
          key: "modeService",
          label: "Features",
          sortable: true,
          thClass: "wsnw",
          tdClass: "wsnw",
        },
        { key: "Admin", tdClass:'text-center', thClass:'text-center' },
        { key: "Operations", tdClass:'text-center', thClass:'text-center' },
        { key: "Finance", tdClass:'text-center', thClass:'text-center' },
      ],
      items:[
        {
          modeService : "Manage Orders",
          Admin   : true,
          Operations     : true,
          Finance   : false
        },
        {
          modeService : "NDR",
          Admin   : true,
          Operations     : true,
          Finance   : false
        },
        {
          modeService : "Billing",
          Admin   : true,
          Operations     : false,
          Finance   : true
        },
        {
          modeService : "Reconciliation",
          Admin   : true,
          Operations     : true,
          Finance   : true
        },
        {
          modeService : "COD",
          Admin   : true,
          Operations     : false,
          Finance   : true
        },
        {
          modeService : "Customization Settings",
          Admin   : true,
          Operations     : true,
          Finance   : false
        },
        {
          modeService : "API",
          Admin   : true,
          Operations     : false,
          Finance   : false
        },
        {
          modeService : "Manage Users",
          Admin   : true,
          Operations     : false,
          Finance   : false
        }
        ],
    };
  },
  components: {
    Multiselect,
    draggable,
  },
  beforeCreate() {
    this.form = {};
  },
  methods: {
    async onSubmit() {
      const data = Object.assign({}, this.form)

      this.submitting = true

      if (this.editUserflag) {
        try {
          const res = await this.axios.post('/userManagement/sellerChild/edit',data)
          if (res.data.status) {
            this.popToast('booked','Success',res.data.message)
            this.isOpen = false
            this.$parent.getItems()
          } else {
            this.popToast('failed','Failed',res.data.error)
          }
        } catch(e) {
          // statements
          console.log(e);
        }
      } else {
        try {
          if (!this.generatPassword) {
            delete data.password
          }
          const res = await this.axios.post('/userManagement/sellerChild/add',data)
          if (res.data.status) {
            this.popToast('booked','Success',res.data.message)
            this.isOpen = false
            this.$parent.getItems()
          } else {
            this.popToast('failed','Failed',res.data.error)
          }
        } catch(e) {
          // statements
          console.log(e);
        }
      }

      this.submitting = false
    },
    hideModal() {
      this.form = {};
      this.$bvModal.hide("add-user-modal");
      this.$emit('update:userEmail', null)
      this.$emit('update:editUserflag', false)
    },
    async formatting(Email) {
      try {
        const res = await this.axios.get(`/userManagement/sellerChild/${Email}`)
        if (res.data.success) {
          const editabledata = {
            id:res.data.data[0].id,
            userName:res.data.data[0].name,
            userEmail:res.data.data[0].email,
            contactNumber:res.data.data[0].contact,
            userRole:res.data.data[0].role
          }

          this.form = editabledata
        } else {
          this.popToast('failed','Failed',"Date Fetching Failed")
        }
      } catch(e) {
        // statements
        console.log(e)
        this.popToast('failed','Failed',"Date Fetching Failed")
      }
    }
  },
  watch: {
    isOpen: function (newValue) {
        if (newValue == false) {
          this.hideModal();
        } else {
          this.generatPassword = false
          if (this.userEmail) {
            this.formatting(this.userEmail)
          } 
          
        }
    }
  },
};
</script>

<style scoped>
.file_upload {
  display: none;
}
.file_upload_active {
  display: block;
}
.manual_upload_inactive {
  display: none;
}

.error{
  background: #ffa6a6;
  height: 2.1rem;
  color: red;
  border: 1px #ffa6a6;
  border-radius: 5px;
}
.addUser {
  border-bottom: 2px solid #c8ced6;
}

.add-user-modal .modal-header{
  padding: 1rem 1.5rem 1rem 2rem !important;
  border-bottom: 2px solid #f5f7fa !important;
}

@media only screen and (max-device-width: 400px) {
    .error {
      height: 3.1rem !important;
    }
  }
</style>
