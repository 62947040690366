<template>
	<div class="container-fluid position-relative">
		<div class="d-flex pb-22 align-items-center justify-content-between">
			<span class="fw700 pb-md-0"></span>
			<span class="d-flex" v-b-tooltip.hover.left :title=" disableAdd ? 'You have reached maximum user limit' : ''">
				<b-button v-b-tooltip.hover.left variant="primary" :disabled="disableAdd" @click="addNewUser">
				<img src="@/assets/images/adduser.png" alt="ADD_USER_IMG" class="adduser mr-5">
				Add New User</b-button>
			</span>
			
		</div>

<div v-if="this.windowWidth > 767">
	<b-table
	:items="items"
	:fields="fields"
	:per-page="perPage"
	:busy="isBusy"
	sticky-header
	no-border-collapse
	class="light-header manifests"
	id="manifests-table"
	>
	<!-- Table Busy loader -->
	<template #table-busy>
		<div class="text-center text-danger my-2">
			<b-spinner class="align-middle"></b-spinner>
			<strong>Loading...</strong>
		</div>
	</template>
	<template v-slot:cell(id)="data">
		{{ data.item.id }}
	</template>
	<!-- A custom formatted data column cell for field 'Date' -->
	<template v-slot:cell(date)="data">
		<div class="w74 d-block">
			{{ data.item.date | date }}
		</div>
	</template>
	<!-- A custom formatted header cell for field 'selected' -->

	<!-- Example scoped slot for select state illustrative purposes -->

	<!-- A custom formatted data column cell for field 'Action' -->
	<template v-slot:cell(action)="data">
		<b-button variant="primary btn-xs" @click="editUser(data.item.id)">
			<svg class="editsvg mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"/></svg>
			Edit
		</b-button>
		<b-button variant="primary btn-xs ml-20 ml-md-15" @click="deleteUser(data.item.id)" style="background-color:#fa5a5a !important">
			<svg class="editsvg mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"/></svg>
			Delete
		</b-button>
	</template>

	<!-- A custom formatted data column cell for field 'Value' -->
	<template v-slot:cell(status)="data">
		<div class="d-flex flex-column">
			<span class="text-truncated">
				<b-form-checkbox
				class="d-inline"
				name="default-pickup"
				v-model="data.item.status"
				switch
				@change="userStatus(data.item.id,data.item.status)"
			></b-form-checkbox>
			</span>
			<span class="fs12 mt-2 lh16 text-gray-600 text-truncate">
		        <span style="color: #65d678;" v-if="data.item.status">Enabled</span>
		        <span v-else>Disabled</span>
		      </span>
		</div>
	</template>
</b-table>
</div>
<ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
	<li class="" v-for="item in items" :key="item.id">
		<div class="d-flex justify-content-between w-100">
			<span class="d-block fw500 pb-8 cp">
				User : 
				{{ item.name }}
			</span>
			<div class="d-flex align-items-end justify-content-between">
				<b-button variant="primary btn-xs" @click="editUser(item.id)">
					<svg class="editsvg " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"/></svg>
					Edit
				</b-button>
				<b-button variant="primary btn-xs ml-10 ml-md-10" @click="deleteUser(item.id)" style="background-color:#fa5a5a !important">
					<svg class="editsvg mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"/></svg>
					Delete
				</b-button>
			</div>
		</div>
		<div class="d-flex align-items-end justify-content-between w-100 mb-12">
			<div class="info">
				<span class="carrier-mode">
					{{ item.email }}
				</span>
				<span class="carrier-name">
					{{ item.contact }}
				</span>
			</div>
		</div>
		<div class="d-flex w-100 align-items-end justify-content-between">
			<span class="fs12 lh16 text-gray-600">
				{{ item.role }}
			</span>

			<span class="" :class="item.status">
				<div class="d-flex flex-column">
					<span class="text-truncated">
						<b-form-checkbox
						class="d-inline"
						name="default-pickup"
						v-model="item.status"
						switch
						@change="userStatus(item.id,item.status)"
					></b-form-checkbox>
					</span>
					<span class="fs12 mt-2 lh16 text-gray-600 text-truncate">
				        <span style="color: #65d678;" v-if="item.status">Enabled</span>
				        <span v-else>Disabled</span>
				      </span>
				</div>
			</span>
		</div>
	</li>
</ul>

<!-- <div class="pagination-bar">
	<div class="container-fluid">
		<div class="row">
			<div class="col-4">
				<div class="d-inline-flex wsnw align-items-center">
					<div class="d-block w50px">
						<multiselect
						class="pagination-items__pp"
						v-model="perPage"
						:searchable="false"
						:show-labels="false"
						:allow-empty="false"
						:options="pageOptions"
						@input="setPageNumber"
						></multiselect>
					</div>
					<span
					class="pl-8 fs12 lh24 text-gray-600 pagination-items"
					></span>
				</div>
			</div>
			<div class="col-8 text-right">
				<div class="d-inline-flex ml-auto wsnw align-items-center">
					<p class="fs12 lh24 text-gray-600 pr-14">
						Page: {{ currentPage }}
					</p>

					<b-button-group>
						<b-button
						@click="prevPage"
						:disabled="prevDisabled"
						variant="paginate left"
						>
						<i class="icon-back fs12"></i>
					</b-button>

					<b-button
					@click="nextPage"
					:disabled="nextDisabled"
					variant="paginate right"
					>
					<i class="icon-arrow fs12"></i>
				</b-button>
			</b-button-group>
		</div>
	</div>
</div>
</div>
</div> -->
<ResultNotFound v-if="items.length == 0 && !isBusy" />
<AddNewUser :editUserflag.sync="editUserflag" :userEmail.sync="userEmail" ref="addNewUser" />
</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import FilterSidebar from "@/components/ManifestFilter";
import ResultNotFound from "@/components/ResultNotFound.vue";
import AddNewUser from "@/views/userManagement/addNewUserModal.vue"

export default {
	name: "userTable",
	data() {
		return {
			userEmail:null,
			pageOptions: this.$store.state.pageOptions,
			totalRows: 1,
			currentPage: parseInt(this.$route.query.page) || 1,
			perPage: parseInt(this.$route.query.offset) || this.$store.state.perPage,
			totalPages: 1,
			prevDisabled: true,
			nextDisabled: false,
			startPage: 1,
			disableAdd:false,
			pagesArr: [],
			pagination: {},
			items: [],
			reconciliation:false,
			isBusy: true,
			filterResultsToggle: this.$store.getters.filters.manifest ? true : false,
			allSelected: false,
			selFilters: this.$store.getters.filters.manifest || {},
			warehouses: this.$store.getters.warehouses || {},
			downloading: false,
			editUserflag:false,
			fields: [
			{ key: "name", label : "User Name",thClass: "text-center",tdClass: "text-center paddingfirst" },
			{ key: "email", label: "Email ID",thClass: "text-center",tdClass: "text-center correct" },
			{ key: "contact", label: "Contact No.",thClass: "text-center",tdClass: "text-center correct-pad"  },
			{ key: "role", label: "Role",thClass: "text-center",tdClass: "text-center correct-pad"  },
			{ key: "status",thClass: "text-center",tdClass: "text-center correct-pad" },
			{ key: "action", thClass: "text-center",tdClass: "text-center" },
			],
		};
	},
	components: {
		Multiselect,
		FilterSidebar,
		ResultNotFound,
		AddNewUser
	},
	created() {
		this.getItems()
		// if (!this.$store.state.global.showUsers) {
		// 	this.$router.go(-1)
		// } 
	},
	methods: {
		addNewUser() {
			if (!this.disableAdd) {
				this.$refs.addNewUser.isOpen = !this.$refs.addNewUser.isOpen
			}
		},
		editUser(email) {
			this.userEmail = null
			this.userEmail = email
			this.editUserflag = true
			this.$refs.addNewUser.isOpen = !this.$refs.addNewUser.isOpen
		},
		async userStatus(Id,flag) {
			const body = {
				id : Id,
				enabled : flag == true ? 1 : 2
			}
			try {
				const res = await this.axios.post('/userManagement/sellerChild/status',body)
				if (res.data.status) {
					this.popToast('booked','Success',res.data.message)
				} else {
					this.popToast('failed','Failed',res.data.message)
				}
			} catch(e) {
				this.popToast('failed','Failed','Updation Failed!!. Please Try Again Later')
				console.log(e);
			}
		},

		async deleteUser(Id) {
			if(!confirm('Are you sure you want to delete this user?')) {
				return false;
			}
			try {
				const res = await this.axios.post('/userManagement/sellerChild/delete',{id : Id})
				if (res.data.status) {
					this.popToast('booked','Success',res.data.message)
					this.getItems();
				} else {
					this.popToast('failed','Failed',res.data.message)
				}
			} catch(e) {
				this.popToast('failed','Failed','Updation Failed!!. Please Try Again Later')
				console.log(e);
			}
		},

		async getItems(params) {

			try {

				this.isBusy = true

				const res = await this.axios.get('/userManagement/sellerChild/list', {params:params})

				this.items = res.data.data ? res.data.data.map(item => {

					let returnItems = {
						id:item.id,
						name:item.name,
						email:item.email,
						contact:item.contact,
						role:item.role,
						status: item.status == 1 ? true : item.status == 2 ? false : "",
					};
					return returnItems;
				}) : []

				this.isBusy = false

				this.disableAdd = this.items.length == 20 ? true : false;

			} catch (error) {
				this.isBusy = false
				this.nextDisabled = true
				console.log(error)
				// this.popToast('failed', 'Failed!', error)
			}
		},
		// setPagination() {
		// 	this.nextDisabled = this.pagination.next ? false : true
		// 	this.prevDisabled = this.pagination.prev ? false : true
		// 	this.$router.push(`?page=${this.currentPage}&offset=${this.perPage}`).catch(()=>{})
		// },
		// pushTotalPages() {
		// 	this.pagesArr = [];
		// 	for (var i = 1; i <= this.totalPages; i++) {
		// 		this.pagesArr.push(i);
		// 	}
		// },
		// setPageNumber() {
		// 	this.currentPage = 1
		// 	this.getItems()
		// 	this.totalPages = Math.ceil(this.totalRows / this.perPage);
		// 	this.pagesArr = [];
		// 	for (var i = 1; i <= this.totalPages; i++) {
		// 		this.pagesArr.push(i);
		// 	}

		// 	this.prevDisabled = true;
		// 	if (this.totalPages == 1) {
		// 		this.nextDisabled = true;
		// 	} else if (this.totalPages > 1) {
		// 		this.nextDisabled = false;
		// 	}
		// },
		// updatePageNumber() {
		// 	this.totalPages = Math.ceil(this.totalRows / this.perPage);
		// 	this.pagesArr = [];
		// 	for (var i = 1; i <= this.totalPages; i++) {
		// 		this.pagesArr.push(i);
		// 	}
		// },
		// rowClicked(item) {
		// 	this.$router.push(`/shipments?manifestID=${item.id}`)
		// }, 
		// nextPage() {
		// 	this.currentPage++;
		// 	this.getItems()
		// },
		// prevPage() {
		// 	this.currentPage--
		// 	this.getItems()
		// },
		// toggleAllRows() {
		// 	if (this.allSelected == true) {
		// 		this.items.forEach((item) => {
		// 			if(item.shipments > 0 && item.carrier != 'Shypmax') {
		// 				this.$set(item, "selected", true);
		// 			}
		// 			this.allSelected = true;
		// 		});
		// 	} else {
		// 		this.selectedRows.forEach((item) => {
		// 			// this.$delete(item, "selected");
		// 			this.$set(item, "selected", false);
		// 			this.allSelected = false;
		// 		});
		// 	}
		// },
		// clearSelected() {
		// 	this.selectedRows.forEach((item) => {
		// 		this.$delete(item, "selected");
		// 	});
		// 	this.allSelected = false;
		// },
		async downloadManifests() {
			try {
				this.downloading = true
				this.popToast("requested", "Download requested", "Your download should start now.");
				let ids = ''
				let closedIds = []
				this.selectedRows.map(item => {
					closedIds.push(item.id)
					ids +='-'+ item.id
				})
				
				const res = await this.axios.get(`/manifestpdf/${ids.substring(1)}`)
				if(res.data.path) {
					this.items.filter(i => closedIds.indexOf(i.id)>=0).map(item => item.status='close')
					this.download(res.data.path, res.data.name)
				}
				this.clearSelected();
			} catch(e) {
				console.log(e);
			}
			this.downloading = false
		},
		async downloadOne(id, carrier, status) {
			if (carrier == 'DHL') {
				if (status == 'open') {
					try {
						let result = await this.axios.get(`/manifestpdfdhl/${id}`);

						if(result) {
							this.popToast("booked", "Manifest has been successfully generated.", "You will receive an email from DHL shortly.");
						}
					}
					catch(err) {
						console.log(err)
						this.popToast("failed", "Failed.", "DHL Manifest Failed.");
					}
				}
				else {
					this.popToast("booked", "Manifest is already generated.", 'Please check for an email from DHL on your registered email ID');
				}
			}
			else {
				try {
					this.popToast("requested", "Download requested", "Your download should start now.");
					const res = await this.axios.get(`/manifestpdf/${id}`)
					if(res.data.path) {
						const index = this.items.findIndex(i => i.id == id)
						this.items[index].status = 'close'
						this.download(res.data.path, res.data.name)
					}
				} catch(e) {
					console.log(e);
				}
			}
		},
		downloadOrders() {
			this.popToast("requested", "Download requested", "Your download should start now.");
			this.filterResultsToggle = false;
		},
		getFilters() {
			this.currentPage = 1
			this.filterResultsToggle = true;
			this.selFilters = this.$store.getters.filters.manifest
			this.getItems()
		},
		removeFilter(index) {
			const selFilters = {...this.selFilters}
			if(index == 'dateEnd' || index == 'dateStart') {
				delete selFilters.dateEnd
				delete selFilters.dateStart
				delete this.$refs.filter.selectedFilters.dateEnd
				delete this.$refs.filter.selectedFilters.dateStart
				delete this.$refs.filter.form.date
			}else{
				delete selFilters[index]
				delete this.$refs.filter.selectedFilters[index]
			}
			this.selFilters = selFilters
			this.$store.commit('setFilter', {'manifest': selFilters})
			delete this.$refs.filter.form[index]
			if(Object.keys(this.selFilters).length > 0) {
				this.getItems()
			}else{
				this.clearFilters()
			}
		},
		clearFilters() {
			this.filterResultsToggle = false;
			this.selFilters = {};
			this.$refs.filter.clearFilters()
			this.getItems({filtering: false})
		},
		openCancelModal() {
			this.$refs.cancelOrderModal.isOpen = !this.$refs.cancelOrderModal.isOpen;
		},
		goShipment(id) {
			this.$router.push(`/shipments?manifestID=${id}`)
		}
	},
	computed: {
		windowWidth() {
			return this.$store.state.windowWidth;
		},
		selectedRows() {
			const items = this.items.filter((item) => item.selected)
			if(items.length>0) {
				this.allSelected = true
			}
			return items
		},
    // filters() {
    //   return this.$store.getters.orderFilters;
    // }
},
watch: {
	currentPage: function() {
		this.prevDisabled = false;
		this.nextDisabled = false;

		if (this.currentPage == 1) {
			this.prevDisabled = true;
		}

		if (this.currentPage == this.totalPages) {
			this.nextDisabled = true;
		}
	},
	selectedRows: function() {
		if (this.selectedRows.length == this.items.length) {
			this.allSelected = true;
		}
	},
},
mounted() {
	this.totalRows = this.items.length;
	this.totalPages = Math.ceil(this.totalRows / this.perPage);
	for (var i = 1; i <= this.totalPages; i++) {
		this.pagesArr.push(i);
	}
},
};
</script>

<style lang="scss" scoped>
	.light-header.manifests {
		margin-top:unset !important;
	}
	.tag {
		font-weight: 800;
	}
	/deep/.correct {
		padding: 1rem 1.125rem 0.75rem 0 !important;
	}
	/deep/ .correct-pad {
	    padding-top: 1rem;
	} 
	.adduser {
		height: 1rem;
    	width: 1rem;
	}

	.editsvg {
		height: 0.85rem;
		width:1rem;

		path {
			fill:white !important;
		}
	} 
	/deep/ .paddingfirst {
	    padding: 0 0.75rem 1rem 0.5rem !important;
	}
</style>
